
import './init.css'

export const InitSpin = ()=>{
    return(<>
        
        <div>
            <div className='init'>

            </div>
            <p className='sam_abs' style={{
                color:'#222', 
            fontSize:'14px', 
            wordSpacing:'5px',
            letterSpacing:'1px',
            paddingTop:'13px',
            }}>The Era of <b style={{color:'rgb(234, 62, 35)'}}>AI Powered</b></p>

        </div>
        
    </>)
};