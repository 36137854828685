
import './m65.css'

export const Ms365 = ()=>{
    return(<>
        

        <div>
            <div className='ms3655'>

            </div>
            <p className='txtx' style={{
                color:'#222', 
            fontSize:'14px', 
            wordSpacing:'5px',
            letterSpacing:'1px',
            paddingTop:'13px',
            }}>Microsoft <b style={{color:'rgb(234, 62, 35)'}}>365</b></p>

        </div>
        
    </>)
};