
import './sharepre.css'

const SharePre = ()=>{
    return(<>
        

        <div>
            <div className='pre'>

            </div>
            <p className='preeee' style={{
                color:'#222', 
            fontSize:'14px', 
            wordSpacing:'5px',
            letterSpacing:'1px',
            paddingTop:'13px',
            }}>SharePoint Premium...</p>

        </div>
        
    </>)
};

export default SharePre;